.container {
  width: 1100px;
  margin: auto;
}

div.invalid p {
  color: red;
}

div.invalid input, div.invalid textarea {
  border: 1px solid red;
}

@media (max-width: 1000px) {
  .container {
    width: 100vw;
  }
}
