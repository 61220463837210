.form {
  margin: 30px auto 0;
  width: 600px;
}

.control-group {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  gap: 20px;
  margin-bottom: 18px;
}

.input-group {
  display: flex;
  gap: 3px;
  flex-direction: column;
  width: 100%;
}

.input-group input,
.input-group textarea {
  padding: 7px 5px;
  width: 100%;
  outline: none;
  border: 1.3px solid var(--primary-color);
  border-radius: 6px;
}

.input-group input:focus,
.input-group textarea:focus {
  box-shadow: 0 0 5pt 0.5pt var(--secondary-color);
  outline-width: 0px;
}

.input-group p {
  font-size: 14px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.submit-button {
  margin: 14px 0;
  border: none;
  outline: none;
  padding: 12px 20px;
  width: 100px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button:not(:disabled):hover {
  opacity: 0.7;
}

.submit-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

@media (max-width: 1000px) {
  .form {
    width: 100%;
    padding: 0 10px;
  }

  .control-group {
    flex-direction: column;
    font-size: 16px;
    gap: 10px;
    margin-bottom: 10px;
  }

  .input-group p {
    font-size: 12px;
  }
}
