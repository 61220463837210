.footer {
  margin-top: 150px;
}

.hr {
  width: 95vw;
  margin-top: 30px;
  border: none;
  height: 4px;
  margin-top: 0;
  border-top: 1px solid var(--secondary-color);
  box-shadow: 0px -11px 15px 0px rgba(192, 158, 44, 0.75);
  margin: -50px auto 10px;
}

.footer-container {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer-left {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.footer-title {
  font-size: 22px;
  letter-spacing: 5px;
  text-align: center;
}

.pages {
  display: flex;
  gap: 30px;
  font-size: 18px;
}

.page-link:hover {
  opacity: 0.7;
}

.footer-right {
  font-size: 18px;
}

.footer-right a {
  margin-left: 15px;
}

.footer-right a:hover {
  text-decoration: underline;
}

@media (max-width: 1000px) {
  .footer {
    margin-top: 100px;
  }

  .hr {
    width: 100vw;
  }

  .footer-container {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-left {
    gap: 5px;
  }

  .footer-title {
    font-size: 18px;
    letter-spacing: 3px;
  }

  .pages {
    font-size: 14px;
  }

  .footer-right {
    font-size: 14px;
  }

  .footer-right a {
    margin-left: 5px;
  }
}
