.person {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
  border-bottom: 2px solid var(--secondary-color);
  border-top: 2px solid var(--secondary-color);
  margin: 60px 0;
}

.person-left {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.person-img {
  width: 280px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
}

.person-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.symbol {
  width: 175px;
  margin-bottom: 20px;
}

.title {
  letter-spacing: 4px;
  font-style: italic;
  font-size: 30px;
  color: var(--dark-color);
}

.desc {
  letter-spacing: 3px;
  font-size: 18px;
}

@media (max-width: 1000px) {
  .person {
    flex-direction: column;
    gap: 0px;
    margin: 0px 0 50px;
  }

  .person-left {
    padding: 20px;
  }

  .person-img {
    width: 50%;
  }

  .person-right {
    gap: 5px;
  }

  .symbol {
    width: 20%;
    margin-bottom: 10px;
  }

  .title {
    letter-spacing: 4px;
    font-size: 22px;
  }

  .desc {
    letter-spacing: 3px;
    font-size: 15px;
    margin-bottom: 10px;
  }
}
