@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&display=swap");

:root {
  --primary-color: #f2e8c6;
  --secondary-color: #c09e2c;
  --dark-color: #333;
  --white-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--dark-color);
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--primary-color);
}

ul {
  list-style: none;
}

img {
  width: 100%;
}
