.post {
  margin-top: 80px;
}

.title {
  position: relative;
  font-size: 30px;
  text-align: center;
  line-height: 1.5em;
  padding-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--dark-color);
}

.title:before {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 80%;
  left: 50%;
  margin-left: -40%;
  height: 2px;
  content: "";
  background-color: var(--secondary-color);
  z-index: 4;
}

.title:after {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  bottom: 0px;
  content: "\00a7";
  font-size: 30px;
  line-height: 40px;
  color: var(--secondary-color);
  font-weight: 400;
  z-index: 5;
  display: block;
  background-color: var(--primary-color);
}

.post-wrapper {
  padding: 10px;
  width: 800px;
  margin: auto;
  display: flex;
  gap: 20px;
}

.post-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.post-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.post-title {
  font-size: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-continue {
  padding: 7px 12px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.post-continue:hover {
  opacity: 0.7;
}

@media (max-width: 1000px) {
  .post {
    margin-top: 50px;
  }

  .title {
    font-size: 22px;
    line-height: 1.2em;
    padding-bottom: 40px;
  }

  .title:before {
    width: 90%;
    margin-left: -45%;
    height: 1px;
  }

  .title:after {
    font-size: 25px;
  }

  .post-wrapper {
    padding: 10px;
    width: 100%;
    margin: 0;
    gap: 10px;
    flex-direction: column;
  }

  .post-img {
    height: 250px;
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 5px;
  }

  .post-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .post-title {
    font-size: 20px;
    text-decoration: underline;
  }

  .post-p {
    font-size: 14px;
  }
}
