.item {
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 25px;
  text-decoration: underline;
  text-align: center;
}

.title-link {
  transition: all 0.2s;
}

.title-link:hover {
  opacity: 0.7;
}

.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-continue {
  padding: 7px 12px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  align-self: flex-end;
}

.post-continue:hover {
  opacity: 0.7;
}

.writer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-top: 1px solid var(--secondary-color);
  padding-top: 5px;
}

.icon {
  width: 20px;
}

@media(max-width:1000px) {
  .item {
    width: 100%;
    gap: 5px;
    padding: 0 10px;
  }
  
  .title {
    font-size: 18px;
  }
  
  .post-continue {
    width: 100%;
    margin: 5px 0;
  }
  
  .post-continue:hover {
    opacity: 0.7;
  }
  
  .content {
    font-size: 15px;
  }

  .writer {
    gap: 5px;
    font-size: 14px;
  }
  
  .icon {
    width: 20px;
  }
  
}