.contact-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
}

.title {
  letter-spacing: 3px;
  font-size: 30px;
  margin-bottom: 15px;
}

.email {
  text-decoration: underline;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tel {
  text-decoration: underline;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item img {
  width: 50px;
  height: 50px;
}

@media (max-width: 1000px) {
  .contact-header {
    gap: 5px;
    font-size: 16px;
    padding: 0 10px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
