.about-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 30px;
}

.title {
    letter-spacing: 6px;
    font-size: 30px;
    margin-bottom: 5px;
}

.subtitle {
    font-size: 14px;
    font-weight: lighter;
    font-style: italic;
}

.about-main {
    width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
}

@media(max-width:1000px) {
    .about-header {
        margin-bottom: 20px;
    }
    
    .title {
        font-size: 24px;
        letter-spacing: 3px;
    }
    
    .subtitle {
        font-size: 12px;
    }
    
    .about-main {
        padding: 0 10px;
        width: 100%;
        gap: 10px;
        font-size: 16px;
    }
}