.quote {
  display: flex;
  gap: 30px;
}

.quote-left {
  width: 60%;
}

.quote-img {
  height: 400px;
  object-fit: cover;
}

.quote-right {
  width: 40%;
  display: flex;
  gap: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 35px;
  border: 2px solid var(--secondary-color);
}

.content {
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 18px;
  font-weight: lighter;
}

.writer {
  font-weight: lighter;
  font-size: 23px;
  letter-spacing: 5px;
  font-style: italic;
}

@media (max-width: 1000px) {
  .quote {
    flex-direction: column;
    gap: 5px;
  }

  .quote-left {
    width: 100%;
    padding: 0 5%;
  }

  .quote-img {
    height: 250px;
    border: 2px solid var(--secondary-color);
    border-radius: 20px;
  }

  .quote-right {
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    gap: 20px;
    padding: 20px;
  }

  .content {
    line-height: 19px;
    font-size: 15px;
  }

  .writer {
    font-size: 20px;
    letter-spacing: 4px;
  }
}
