.nav {
    display: flex;
    justify-content: center;
}

.nav-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    font-size: 20px;
    width: 600px;
}

.nav-list-item {
    width: 100%;
    display: flex;
    justify-content: center;
    transition: all .3s;
}

.nav-list-item:hover {
    opacity: .7;
    transform: scale(1.05);
}

.nav-link {
    padding: 8px 12px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 10px;
    width: 100%;
    text-align: center;
}

@media (max-width: 1000px) {
    .control-nav {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        background-color: var(--secondary-color);
    }

    .menu {
        width: 30px;
    }
    
    .nav-list {
        position: absolute;
        background-color: var(--secondary-color);
        flex-direction: column;
        gap: 5px;
        margin-top: 0px;
        width: 100%;
    }
    
    .nav-list-item:not(:last-child) {
        border-bottom: 1px solid var(--primary-color);
    }
    
    .nav-link {
        border-radius: 0px;
    }
}