.articles-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  margin-bottom: 30px;
}

.title {
  letter-spacing: 3px;
  font-size: 30px;
  margin-bottom: 15px;
}

.articles-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1000px) {
  .articles-header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .title {
    font-size: 24px;
  }

  .articles-list {
    gap: 30px;
  }
}
