.detail-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin-bottom: 25px;
}

.writer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
  border-bottom: 1px solid var(--secondary-color);
}

.icon {
  width: 20px;
}

.detail-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
}

.contentP {
  margin-bottom: 8px;
}

.detail-main,
.article-footer {
  width: 1000px;
  margin: auto;
}

.img-wrapper {
  width: 350px;
  height: 350px;
  margin: auto;
}

.article-img {
  height: 100%;
  object-fit: cover;
  border: 2px solid var(--secondary-color);
}

.article-footer {
  margin-top: 10px;
}

.article-footer h3 {
  font-style: italic;
  letter-spacing: 7px;
  margin-bottom: 4px;
}

.bibliography {
  list-style-type: square;
  margin-left: 20px;
}

.bibliography_item {
  letter-spacing: 1px;
}

@media (max-width: 1000px) {
  .detail-header {
    margin-bottom: 15px;
  }

  .title {
    font-size: 20px;
  }

  .writer {
    font-size: 14px;
    gap: 5px;
  }

  .detail-main {
    gap: 15px;
    font-size: 16px;
  }

  .contentP {
    margin-bottom: 5px;
  }

  .detail-main,
  .article-footer {
    width: 100%;
    margin: auto;
    padding: 0 10px;
  }

  .img-wrapper {
    width: 100%;
    height: 300px;
  }

  .article-footer {
    margin-top: 5px;
  }

  .article-footer h3 {
    letter-spacing: 3px;
    font-size: 18px;
    margin-bottom: 4px;
  }

  .bibliography_item {
    font-size: 14px;
  }
}
